import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
    getProductsByCatAlogueId
} from "../../../Store/catelogue/actions";
import EMailVerification from "../../products/EMailVerification";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useLocation } from 'react-router-dom';
import { isArray } from 'lodash';
import EComerceProduct from './EComerceProduct';
import useProductDataLoop from '../useProductDataLoop';
import HeaderEcomerce from './HeaderEcomerce';
var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
var productDataArr: any = [];
const PublicCatalogueEcomerce = (props: any) => {
    const location: any = useLocation();
    const catalogueId: any = location?.pathname.split("/")[2];
    const [loading, setLoading] = useState(false);
    let productVerficationCode = localStorage.getItem("productVerificationToken");

    const [kycFlag, setKycFlag]: any = useState(false);
    const [openModal, setOpenModal]: any = useState(
      productVerficationCode?.length ? false : true
    );
    const closeModal = (value: any) => {
      setOpenModal(value);
    };
    const kycFlagFun = (value: any) => {
        setKycFlag(value);
      };
    let { productData, openPreview, handlePreview, image, checkedProductData } = useProductDataLoop(productDataArr)
    // useEffect(() => {
    //     getAllCatalogueProductData();
    // }, [])
    // get all catalogue products
    const getAllCatalogueProductData = () => {
        setLoading(true)
        let payload = {
            offset: offset,
            limit: limit,

        }
        props.getProductsByCatAlogueId(payload, catalogueId, (res: any) => {
            setLoading(false)
            if (isArray(res?.data?.docs)) {
                totCnt = res?.data?.length;
                totPages = Math.ceil(totCnt / 10);
                fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
                toVal =
                    res?.data?.offset * 10 +
                    res?.data?.docs?.length;
                productDataArr = res?.data?.docs
            }
        })
    }
    const handleChangePage = (event: any, value: any) => {
        offset = (value - 1) * 1;
        getAllCatalogueProductData();
    };
    return (
        <>
              <Modal
        isOpen={openModal}
        // toggle={closeModal}
        size="lg"
        className="ArtFormModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Sign Up</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <EMailVerification
            {...props}
            closeModal={closeModal}
            getAllData={getAllCatalogueProductData}
            kycFlagFun={kycFlagFun}
          />
        </ModalBody>
      </Modal>
            <HeaderEcomerce {...props}  route={'publicRoute'}/>
            <br /> <br /> <br /> <br />
            <EComerceProduct
                {...props}
                productData={productData}
                totCnt={totCnt}
                totPages={totPages}
                fromVal={fromVal}
                offset={offset}
                handleChange={handleChangePage}
                route={'catalogue-product-info'}
            />
        </>
    )
}

export default connect(null, {
    getProductsByCatAlogueId,
})(PublicCatalogueEcomerce);