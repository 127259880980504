import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import {
  postApi,
  getApi,
  deleteApi,
  putApi,
  getApiForPhoneOtp,
  postApiforOtp,
  getApiForProductCertification,
} from "../../helpers/auth_helpers";

// workers
function* workerGetProducts({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let ProductId = filters?.ProductId ? filters?.ProductId : "";
  let ProductsName = filters?.ProductsName ? filters?.ProductsName : "";
  let categoryId = filters?.categoryId ? filters?.categoryId : "";
  let subCategoryId = filters?.subCategoryId ? filters?.subCategoryId : "";
  let weaveTypeId = filters?.weaveTypeId ? filters?.weaveTypeId : "";
  let artformId = filters?.artformId ? filters?.artformId : "";
  let typeId = filters?.typeId ? filters?.typeId : "";
  let sortBy = filters?.sortBy ? filters?.sortBy : "createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy : "-1";
  let tags = filters?.tags ? filters?.tags : "";
  let xuid = filters?.xuid ? filters?.xuid : '';
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/product/products?offset=" +
      offset +
      "&limit=" +
      limit +
      "&name=" +
      ProductsName +
      "&productId=" +
      ProductId +
      "&artFormId=" +
      artformId +
      "&typeId=" +
      typeId +
      "&categoryId=" +
      categoryId +
      "&subcategoryId=" +
      subCategoryId +
      "&weavetypeId=" +
      weaveTypeId +
      "&sortBy=" +
      sortBy +
      "&tags=" +
      tags +
      "&orderBy=" +
      orderBy + "&xuid=" + xuid;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetProductById({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let ProductId = filters?.ProductId ? filters?.ProductId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/product/productById?offset=" +
      offset +
      "&limit=" +
      limit +
      "&productId=" +
      ProductId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_PRODUCTS_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetProductByIdAfterLycToken({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let ProductId = filters?.ProductId ? filters?.ProductId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/product/singleProductById?offset=" +
      offset +
      "&limit=" +
      limit +
      "&productId=" +
      ProductId;
    const response: AxiosResponse = yield call(
      getApiForProductCertification,
      url
    );
    callback(response);
    yield put({
      type: types.GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetProductByIdForProductCertification({
  filters,
  callback,
}: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let ProductId = filters?.ProductId ? filters?.ProductId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/product/productById?offset=" +
      offset +
      "&limit=" +
      limit +
      "&productId=" +
      ProductId;
    const response: AxiosResponse = yield call(
      getApiForProductCertification,
      url
    );
    callback(response);
    yield put({
      type: types.GET_PRODUCTS_BY_ID_SUCCESS_PRODUCT_CERTIFICATION,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateProducts({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/product/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateProducts({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/product/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteProducts({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/product/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    yield put({
      type: types.DELETE_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerProductVerifyOtp({ payload, callback }: any) {
  let mobile: any = payload?.mobile;
  let otp: any = payload?.otp;
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/otp/generate";

    const response: AxiosResponse = yield call(postApi, url);
    callback(response);
    yield put({
      type: types.SEND_OTP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerSendOtp({ payload, callback }: any) {
  let mobile: any = payload?.mobile;
  let otp: any = payload?.otp;
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/otp/generate";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);

    yield put({
      type: types.PRODUCT_VERIFY_OTP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerReSendOtp({ payload, callback }: any) {
  let mobile: any = payload?.mobile;
  let otp: any = payload?.otp;
  try {
    const url: string =
      process.env.REACT_APP_OTP_URL +
      "/otp/verify?authkey=370481Aq8yhs5eJ7461b06d95P1&mobile=91" +
      mobile +
      "&otp=" +
      otp;
    const response: AxiosResponse = yield call(postApiforOtp, url);
    callback(response);

    yield put({
      type: types.PRODUCT_VERIFY_OTP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workeRProductKYC({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/productkyc/kycToken";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.PRODUCT_KYC_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateProductVariant({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/varient/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_PRODUCT_VARIANT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// function* workerSendOtp({ payload, callback }: any) {
//   try {
//     const url: string = process.env.REACT_APP_OTP_URL + "/product/sendOTP";
//     const response: AxiosResponse = yield call(getApiForPhoneOtp, url);
//     callback(response);
//     yield put({
//       type: types.SEND_OTP_SUCCESS,
//       payload: response,
//     });
//   } catch (e) {
//     console.log(e);
//   }
// }

function* workerChangeProductStatus({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/product/togglearchive";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CHANGE_PRODUCT_STATUS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCollectFakeAlertDetails({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/Certificate/save-report-data";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.COLLECT_FAKE_ALERT_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerTransferProduct({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/product/transferProductOwnership";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.TRANSFERR_PRODUCT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUndoTransferProduct({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/product/undotransferProductOwnership";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UNDO_TRANSFERED_PRODUCT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerAddNfcTagId({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/product/addnfcTags";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.ADD_NFC_TAG_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchTransferProduct() {
  yield takeEvery(types.TRANSFERR_PRODUCT, workerTransferProduct);
}
export function* watchUndoTransferProduct() {
  yield takeEvery(types.UNDO_TRANSFERED_PRODUCT, workerUndoTransferProduct);
}
export function* watchAddNfcTagId() {
  yield takeEvery(types.ADD_NFC_TAG, workerAddNfcTagId);
}
export function* watchCollectFakeAlertDetails() {
  yield takeEvery(
    types.COLLECT_FAKE_ALERT_DETAILS,
    workerCollectFakeAlertDetails
  );
}
export function* watchGetProducts() {
  yield takeEvery(types.GET_PRODUCTS, workerGetProducts);
}
export function* watchGetProductsById() {
  yield takeEvery(types.GET_PRODUCTS_BY_ID, workerGetProductById);
}
export function* watchGetProductsByIdProductCer() {
  yield takeEvery(
    types.GET_PRODUCTS_BY_ID_PRODUCT_CERTIFICATION,
    workerGetProductByIdForProductCertification
  );
}
export function* watchGetProductByIdAfterLycToken() {
  yield takeEvery(
    types.GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN,
    workerGetProductByIdAfterLycToken
  );
}
export function* watchCreateProducts() {
  yield takeEvery(types.CREATE_PRODUCTS, workerCreateProducts);
}
export function* watchUpdateProducts() {
  yield takeEvery(types.UPDATE_PRODUCTS, workerUpdateProducts);
}
export function* watchDeleteProducts() {
  yield takeEvery(types.DELETE_PRODUCTS, workerDeleteProducts);
}
export function* watchProductVerifyOtp() {
  yield takeEvery(types.PRODUCT_VERIFY_OTP, workerProductVerifyOtp);
}
export function* watchProductSendOtp() {
  yield takeEvery(types.SEND_OTP, workerSendOtp);
}
export function* watchProductReSendOtp() {
  yield takeEvery(types.PRODUCT_RESEND_OTP, workerReSendOtp);
}
export function* watchProductKYC() {
  yield takeEvery(types.PRODUCT_KYC, workeRProductKYC);
}
export function* watchCreateVariantProduct() {
  yield takeEvery(types.CREATE_PRODUCT_VARIANT, workerCreateProductVariant);
}
export function* watchChangeProductStatus() {
  yield takeEvery(types.CHANGE_PRODUCT_STATUS, workerChangeProductStatus);
}
function* productSaga() {
  yield all([fork(watchAddNfcTagId)]);
  yield all([fork(watchUndoTransferProduct)]);
  yield all([fork(watchTransferProduct)]);
  yield all([fork(watchGetProducts)]);
  yield all([fork(watchCreateProducts)]);
  yield all([fork(watchUpdateProducts)]);
  yield all([fork(watchDeleteProducts)]);
  yield all([fork(watchGetProductsById)]);
  yield all([fork(watchProductVerifyOtp)]);
  yield all([fork(watchProductSendOtp)]);
  yield all([fork(watchProductReSendOtp)]);
  yield all([fork(watchProductKYC)]);
  yield all([fork(watchCreateVariantProduct)]);
  yield all([fork(watchChangeProductStatus)]);
  yield all([fork(watchGetProductsByIdProductCer)]);
  yield all([fork(watchGetProductByIdAfterLycToken)]);
  yield all([fork(watchCollectFakeAlertDetails)]);
}
export default productSaga;
