import React, { useState, useEffect } from 'react'
import moment from "moment";
let productsArray: any=[];
const useProductDataLoop = (arrayData: any) => {
    const [productData, setProductData] = useState([]);
    const [image, setImage]: any = useState("");
    const [openPreview, setOpenPreview]: any = useState(false);
    const [checkedProductData, setCheckedProductData]: any = useState([]);
    const handlePreview = () => {
        setOpenPreview(!openPreview);
    };
    useEffect(() => {
        // Process catalogue data when it changes
        processData(arrayData);
    }, [arrayData]);
    const processData = (data: any) => {
        let dataP: any = [];
        data.forEach((ele: any, i: any) => {
            console.log("ele",ele)
            let initialFlag =
                ele?.srNo?.length > 0 ||
                ele?.artform?.name?.length > 0 ||
                ele?.type?.name?.length > 0 ||
                ele?.category?.name?.length > 0 ||
                ele?.subcategory?.name?.length > 0 ||
                ele?.weavetype?.name?.length > 0 ||
                ele?.typeOfSareeByDesign?.length > 0 ||
                ele?.name?.length > 0 ||
                ele?.colour?.length > 0 ||
                ele?.tags?.length > 0 ||
                ele?.brand?.length > 0 ||
                ele?.weaver?.length > 0 ||
                ele?.zari?.length > 0 ||
                ele?.sareeWeight?.length > 0 ||
                ele?.zariWeight?.length > 0 ||
                ele?.purity?.length > 0 ||
                ele?.yarnType?.length > 0 ||
                ele?.loomNum?.length > 0 ||
                ele?.xuid?.length > 0 ||
                ele?.workingHours?.length > 0 ||
                ele?.productionTimeInMonths?.length > 0 ||
                ele?.silk?.length > 0 ||
                ele?.handloom?.length > 0;
            let initialFlagWithMedia = ele?.thumbnailDetails?.media?.length > 0;

            let designFlag = ele?.designingDetails?.karigar?.name?.length > 0;
            let designFlagWithMedia = ele?.designingDetails?.media?.length > 0;
            let dyingFlag = ele?.dyingDetails?.karigar?.name?.length > 0;
            let dyingFlagWithMedia = ele?.dyingDetails?.media?.length > 0;
            let finishingFlag = ele?.finishingDetails?.karigar?.name?.length > 0;
            let finishingFlagWithMedia = ele?.finishingDetails?.media?.length > 0;
            let weavingFlag = ele?.weavingDetails?.karigar?.name?.length > 0;
            let weavingFlagWithMedia = ele?.weavingDetails?.media?.length > 0;

            let pignedImage: any = [];
            let unpign: any = [];
            if (ele?.thumbnailDetails?.media?.length > 0) {
                ele?.thumbnailDetails?.media.map((ele: any, i: any) => {
                    if (ele?.pin === "1" || ele?.pin=== 1 && ele?.show === "1" || ele?.show === 1) {
                        pignedImage.push(ele?.mediaURL);
                    } else if (ele?.show === "1" || ele?.show === 1) {
                        unpign.push(ele?.mediaURL);
                    }
                });
            }
            dataP.push({
                select: <input
                    type="checkbox"
                    onClick={(e) => handleClickProducts(ele, e)}
                />,
                id:ele?.productUniqueId,                
                productName: ele?.name,
                logo:<>{
                    ele?.thumbnailDetails?.media?.length > 0 ? (
                        <img
                            onClick={() => {
                                handlePreview();
                                setImage([...pignedImage, ...unpign]);
                            }}
                            src={[...pignedImage, ...unpign][0]}
                            alt="logo"
                            className="logoImage"
                        ></img>
                    ) : (
                        <span className="logoPreview">Image not uploaded</span>
                    )}</>,
                srNo: ele?.srNo,
                nfcTag: (
                    <span
                        style={{
                            textTransform: "none",
                        }}
                    >
                        {ele?.xuid}
                    </span>
                ),
                status: (
                    <>
                        <div className="ProductFlowStatusParent">
                            <div className="hr"></div>
                            <div className="ProductFlowStatus">
                                <div className="tooltipIcon">
                                    <div className="ps2 ">
                                        <div
                                            className="innerCircle"
                                            style={{
                                                backgroundColor:
                                                    initialFlag && initialFlagWithMedia
                                                        ? "rgb(119 216 119)"
                                                        : initialFlag || initialFlagWithMedia
                                                            ? "rgb(84 115 182)"
                                                            : "#FFF",
                                            }}
                                        ></div>
                                        <span className="tooltipIcontext">
                                            <b>Basic Information&nbsp;: </b> &nbsp;
                                            {initialFlag && initialFlagWithMedia
                                                ? "Basic Information is entered and Media uploaded"
                                                : initialFlag
                                                    ? "Basic Information is entered and Media missing"
                                                    : initialFlagWithMedia
                                                        ? "Basic Information is missing,Media uploaded"
                                                        : "Basic Information and media missing"}
                                        </span>
                                    </div>
                                </div>
                                <div className="tooltipIcon">
                                    <div className="ps1 ">
                                        <div
                                            className="innerCircle"
                                            style={{
                                                backgroundColor:
                                                    designFlag && designFlagWithMedia
                                                        ? "rgb(119 216 119)"
                                                        : designFlag || designFlagWithMedia
                                                            ? "rgb(84 115 182)"
                                                            : "#FFF",
                                            }}
                                        ></div>
                                        <span className="tooltipIcontext">
                                            <b>Designing&nbsp;: </b> &nbsp;
                                            {designFlag && designFlagWithMedia
                                                ? "Karigar is entered and Media uploaded"
                                                : designFlag
                                                    ? "Karigar is entered and Media missing"
                                                    : designFlagWithMedia
                                                        ? "Karigar is missing,Media uploaded"
                                                        : "Karigar and media missing"}
                                        </span>
                                    </div>
                                </div>
                                <div className="tooltipIcon">
                                    <div className="ps3">
                                        <div
                                            className="innerCircle"
                                            style={{
                                                backgroundColor:
                                                    dyingFlag && dyingFlagWithMedia
                                                        ? "rgb(119 216 119)"
                                                        : dyingFlag || dyingFlagWithMedia
                                                            ? "rgb(84 115 182)"
                                                            : "#FFF",
                                            }}
                                        ></div>
                                        <span className="tooltipIcontext">
                                            <b>Dying&nbsp;: </b> &nbsp;
                                            {dyingFlag && dyingFlagWithMedia
                                                ? "Karigar is entered and Media uploaded"
                                                : dyingFlag
                                                    ? "Karigar is entered and Media missing"
                                                    : dyingFlagWithMedia
                                                        ? "Karigar is missing,Media uploaded"
                                                        : "Karigar and media missing"}
                                        </span>
                                    </div>
                                </div>
                                <div className="tooltipIcon">
                                    <div className="ps4">
                                        <div
                                            className="innerCircle"
                                            style={{
                                                backgroundColor:
                                                    weavingFlag && weavingFlagWithMedia
                                                        ? "rgb(119 216 119)"
                                                        : weavingFlag || weavingFlagWithMedia
                                                            ? "rgb(84 115 182)"
                                                            : "#FFF",
                                            }}
                                        ></div>
                                        <span className="tooltipIcontext">
                                            <b>Weaving&nbsp;: </b> &nbsp;
                                            {weavingFlag && weavingFlagWithMedia
                                                ? "Karigar is entered and Media uploaded"
                                                : weavingFlag
                                                    ? "Karigar is entered and Media missing"
                                                    : weavingFlagWithMedia
                                                        ? "Karigar is missing,Media uploaded"
                                                        : "Karigar and media missing"}
                                        </span>
                                    </div>
                                </div>
                                <div className="tooltipIcon">
                                    <div className="ps5 ">
                                        <div
                                            className="innerCircle"
                                            style={{
                                                backgroundColor:
                                                    finishingFlag && finishingFlagWithMedia
                                                        ? "rgb(119 216 119)"
                                                        : finishingFlag || finishingFlagWithMedia
                                                            ? "rgb(84 115 182)"
                                                            : "#FFF",
                                            }}
                                        ></div>
                                        <span className="tooltipIcontext">
                                            <b>Finishing&nbsp;: </b> &nbsp;
                                            {finishingFlag && finishingFlagWithMedia
                                                ? "Karigar is entered and Media uploaded"
                                                : finishingFlag
                                                    ? "Karigar is entered and Media missing"
                                                    : finishingFlagWithMedia
                                                        ? "Karigar is missing,Media uploaded"
                                                        : "Karigar and media missing"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ),
                isTransfered: ele?.transfer === true ? "true" : "false",
                transferTime: ele?.transferTime,
                productOwner: ele?.productOwner,
                designOwner: ele?.designOwner,
                category: ele?.category?.name,
                artform: ele?.artform?.name,
                type: ele?.type?.name,
                color: ele?.color,
                subCategory: ele?.subcategory?.name,
                weaveType: ele?.weavetype?.name,
                orgSrNo: ele?.orgSrNo,
                designNo: ele?.designNum,
                sellPrice:ele?.sellPrice,
                costPrice:ele?.costPrice,
                // orgName: ele?.organizations[0]?.name,
                createdAt: ele?.createdAt
                    ? moment(new Date(ele?.createdAt))
                        .subtract(5, "hours")
                        .subtract(30, "minutes")
                        .format("YYYY-MM-DD LT")
                    : "NA",
                createdBy: ele?.createdBy ? ele?.createdBy?.role : "",
            })

        })
        setProductData(dataP)
            console.log(dataP,"dataP")

    }
    const handleClickProducts = (data: any, e: any) => {
        let productId=data?.productUniqueId
         setCheckedProductData((prevCheckedItems:any) => {
            if (prevCheckedItems.includes(productId)) {
                return prevCheckedItems.filter((item:any) => item !== productId);
            } else {
                return [...prevCheckedItems, productId];
            }
        });

    }

    return {
        productData, openPreview, handlePreview, image, checkedProductData
    }
}

export default useProductDataLoop