import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Card, Button } from 'reactstrap';
//components
import ProductImagePreView from "../products/ProductImagePreView";
import Index from "../../common/Components/Index";
import Loader from '../../common/Components/Loader/Loader';
import { HeaderCatalogueProductData } from './Header';
import useProductDataLoop from './useProductDataLoop';
import notifications from '../../common/Components/Notifications/Notification';
//actions
import {
    getProductsByCatAlogueId,
    deleteProductInCatAlogue,
} from "../../Store/catelogue/actions";
import { isArray } from 'lodash';
import arrowUpIcon from "./assets/images/icons8-arrow-up-48.png";
import arrowDownIcon from "./assets/images/icons8-expand-arrow-26.png";

let catalogDataProduct: any = [];
const AdminproductData = (props: any) => {
    let {
        catalogueId,
        catalogueProductToggal,
        handleCatalogueProductToggal,
        openPreview,
        handlePreview,
        image,
        checkedProductData,
        offset,
        limit,
        totPages,
        totCnt,
        toVal,
        fromVal,
        handlePageChange,
        getAllCatalogueProductData,
        data,
        loading,
        handleResetCheckboxes
    } = props;
    const deleteProductFromCatalogue = (checkedProductData: any) => {
        props.deleteProductInCatAlogue({ productIds: [...checkedProductData] }, catalogueId, (res: any) => {
            if (res.success) {
                notifications({
                    title: "Success!",
                    message: res?.message,
                    type: "success",
                });
                getAllCatalogueProductData();
                handleResetCheckboxes();
            } else {
                notifications({
                    title: "Error!",
                    message: res?.message,
                    type: "danger",
                });
            }
        })

    }
    return (
        <>
            {openPreview && (
                <ProductImagePreView
                    {...props}
                    openModal={openPreview}
                    closeModal={handlePreview}
                    images={image}
                />
            )}
            <Card className='catalogue-product-card'>
                <div className='catalogue-product'>
                    <div>
                        Catalogue Products
                        <Button className='catalogue-btn'
                            disabled={checkedProductData?.length <= 0 ? true : false}
                            onClick={() => deleteProductFromCatalogue(checkedProductData)}
                        >Delete Products From Catalogue</Button>

                    </div>

                    <div>
                        {
                            catalogueProductToggal ?
                                <img className='cursor-pointer'
                                    onClick={() => {
                                        handleCatalogueProductToggal()
                                    }}
                                    src={arrowUpIcon} width={20} height={20} alt="Arrow Up" />
                                : <img className='cursor-pointer'
                                    onClick={() => {
                                        handleCatalogueProductToggal()
                                    }}
                                    src={arrowDownIcon} width={20} height={20} alt="Arrow Up" />
                        }

                    </div>
                </div>

            </Card>
            {
                catalogueProductToggal &&
                <>
                    {
                        data?.length >= 0 ?
                            <>
                                <Index
                                    {...props}
                                    data={data}
                                    headerData={HeaderCatalogueProductData}
                                    limit={limit}
                                    handleChange={handlePageChange}
                                    offset={offset}
                                    totPages={totPages}
                                    loading={loading}
                                    fromVal={fromVal}
                                    toVal={toVal}
                                    totCnt={totCnt}
                                    message="No products added into catalogue"
                                />

                            </> : <>
                            </>
                    }</>
            }



        </>
    )
}
export default connect(null, {
    getProductsByCatAlogueId,
    deleteProductInCatAlogue
})(AdminproductData);