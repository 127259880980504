import React, { useState } from "react";
import { Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";

//components
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import {
    createCatAlogue,
    updateCatAlogue,
} from "../../Store/catelogue/actions";
const AddOrEditCatalogue = (props: any) => {
    const [loading, setLoading]: any = React.useState(false);
    const [form, setForm]: any = React.useState(
        props.editData?._id
            ? {
                name: props.editData?.name,
                description: props?.editData?.description,
            }
            : {
                name: "",
                description: "",
            }
    );
    const [Error, setError]: any = React.useState({
        name: "",
        description: ""
    });
    let error = "";
    const validate = () => {

        let err = Error;
        if (form?.name === "") {
            error = "Required";
            err = {
                ...err,
                name: "Catalogue name is Required!",
            };
        }
        if (form?.description === "") {
            error = "Required";
            err = {
                ...err,
                description: "Description name is Required!",
            };
        }
        setError(err);
        return error;
    };

    const handleSubmit = () => {
        let result: any = validate();
        if (result?.length === 0) {
            setLoading(true);
            props?.editData?._id
                ? props.updateCatAlogue(
                    {
                        catalougeId: props?.editData?._id,
                        name: form?.name,
                        description: form?.description
                    },
                    (res: any) => {
                        setLoading(false);
                        if (res.success) {
                            notifications({
                                title: "Success!",
                                message: res?.message,
                                type: "success",
                            });
                            props.closeModal();
                            props.getAllData();
                        } else {
                            notifications({
                                title: "Error!",
                                message: res?.message,
                                type: "danger",
                            });
                        }
                    }
                )
                : props.createCatAlogue(
                    {
                        name: form?.name,
                        description: form?.description
                    },
                    (res: any) => {
                        setLoading(false);
                        if (res.success) {
                            notifications({
                                title: "Success!",
                                message: res?.message,
                                type: "success",
                            });
                            props.closeModal();
                            props.getAllData();
                            // props.handleName({ name: "name", name: form?.name });
                        } else {
                            notifications({
                                title: "Error!",
                                message: res?.message,
                                type: "danger",
                            });
                        }
                    }
                );
            setForm({
                name: "",
                description: ""
            });
        }
    };
    // handle change
    const handleChange=(e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setForm({
            ...form,
            [name]:value
        })
    }
    return (
        <>
            <div className="AddCatalogue">
                <div className="form">
                    {loading ? (
                        <div className="LoaderDivModal">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <div className="formDiv">
                                <Label>
                                    Name<span>*</span>
                                </Label>
                                <Input
                                    name="name"
                                    className="form-control"
                                    placeholder="Type Name"
                                    value={form?.name}
                                    onChange={(e: any) => {
                                        handleChange(e)
                                    }}
                                />
                                {Error.name && !form?.name ? (
                                    <p className="RequiredField">{Error?.name} </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="formDiv">
                                <Label>
                                    Description<span>*</span>
                                </Label>
                                <Input
                                    name="description"
                                    className="form-control"
                                    placeholder="Description"
                                    value={form?.description}
                                    onChange={(e: any) => {
                                        handleChange(e)
                                    }}
                                />
                                {Error.description && !form?.description ? (
                                    <p className="RequiredField">{Error?.description} </p>
                                ) : (
                                    ""
                                )}
                            </div></>

                    )}
                    <Button className="artButton"
                        onClick={() => handleSubmit()}>
                        {loading ? "Processing" : "Save"}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    createCatAlogue,
    updateCatAlogue,
})(AddOrEditCatalogue);
