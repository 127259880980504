import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Grid } from '@mui/material';
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
//components
import Index from "../../common/Components/Index";
import Loader from '../../common/Components/Loader/Loader';
import { HeaderCatalogueProductData } from './Header';
import AdminCatalogueProducts from './AdminCatalogueProducts';
import AllProducts from './AllProducts';
import notifications from '../../common/Components/Notifications/Notification';
//actions
import {
    getProducts,
} from "../../Store/products/actions";
import {
    getProductsByCatAlogueId
} from "../../Store/catelogue/actions";
import { isArray } from 'lodash';
import { CheckBoxItem } from '../../common/commonFiles/CheckBoxItem';
// variables for catalogue
var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
// variables for add products
var offsetProduct: any = 0;
var limitProduct: any = 10;
var totPagesProduct = 0;
var totCntProduct: any = 0;
var toValProduct: any = 0;
var fromValProduct: any = 0;
const ViewCatalogProducts = (props: any) => {
    const location: any = useLocation();
    const catalogueId: any = location?.pathname.split("/")[2];
    const [image, setImage]: any = useState("");
    const [openPreview, setOpenPreview]: any = useState(false);
    // all product data
    const [checkedAddProductData, setCheckedAddProductData]: any = useState([])
    const [allProductArry, setAllProductArry] = useState([])
    // cards expand
    const [catalogueProductToggal, setCatalogueProductModal] = useState(false);
    const [allProductToggal, setAllProductsToggal] = useState(false)
    // catalogproducts
    const [loading, setLoading] = useState(false);
    const [checkedProductData, setCheckedProductData]: any = useState([]);

    const [catalogueProductarrayItem, setCatalogueProductarryItem] = useState([])
    useEffect(() => {
        getAllCatalogueProductData();
        getAllProductData();
    }, [])
    // get all catalogue products
    const getAllCatalogueProductData = () => {
        setLoading(true)
        let payload = {
            offset: offset,
            limit: limit,

        }
        let catalogueItem: any = [];
        props.getProductsByCatAlogueId(payload, catalogueId, (res: any) => {
            setLoading(false)
            if (isArray(res?.data?.docs)) {
                totCnt = res?.data?.length;
                totPages = Math.ceil(totCnt / 10);
                fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
                toVal =
                    res?.data?.offset * 10 +
                    res?.data?.docs?.length;
                res?.data?.docs.forEach((ele: any, i: any) => {
                    console.log("ele", ele)
                    let initialFlag =
                        ele?.srNo?.length > 0 ||
                        ele?.artform?.name?.length > 0 ||
                        ele?.type?.name?.length > 0 ||
                        ele?.category?.name?.length > 0 ||
                        ele?.subcategory?.name?.length > 0 ||
                        ele?.weavetype?.name?.length > 0 ||
                        ele?.typeOfSareeByDesign?.length > 0 ||
                        ele?.name?.length > 0 ||
                        ele?.colour?.length > 0 ||
                        ele?.tags?.length > 0 ||
                        ele?.brand?.length > 0 ||
                        ele?.weaver?.length > 0 ||
                        ele?.zari?.length > 0 ||
                        ele?.sareeWeight?.length > 0 ||
                        ele?.zariWeight?.length > 0 ||
                        ele?.purity?.length > 0 ||
                        ele?.yarnType?.length > 0 ||
                        ele?.loomNum?.length > 0 ||
                        ele?.xuid?.length > 0 ||
                        ele?.workingHours?.length > 0 ||
                        ele?.productionTimeInMonths?.length > 0 ||
                        ele?.silk?.length > 0 ||
                        ele?.handloom?.length > 0;
                    let initialFlagWithMedia = ele?.thumbnailDetails?.media?.length > 0;

                    let designFlag = ele?.designingDetails?.karigar?.name?.length > 0;
                    let designFlagWithMedia = ele?.designingDetails?.media?.length > 0;
                    let dyingFlag = ele?.dyingDetails?.karigar?.name?.length > 0;
                    let dyingFlagWithMedia = ele?.dyingDetails?.media?.length > 0;
                    let finishingFlag = ele?.finishingDetails?.karigar?.name?.length > 0;
                    let finishingFlagWithMedia = ele?.finishingDetails?.media?.length > 0;
                    let weavingFlag = ele?.weavingDetails?.karigar?.name?.length > 0;
                    let weavingFlagWithMedia = ele?.weavingDetails?.media?.length > 0;

                    let pignedImage: any = [];
                    let unpign: any = [];
                    if (ele?.thumbnailDetails?.media?.length > 0) {
                        ele?.thumbnailDetails?.media.map((ele: any, i: any) => {
                            if (ele?.pin === "1" || ele?.pin === 1 && ele?.show === "1" || ele?.show === 1) {
                                pignedImage.push(ele?.mediaURL);
                            } else if (ele?.show === "1" || ele?.show === 1) {
                                unpign.push(ele?.mediaURL);
                            }
                        });
                    }
                    catalogueItem.push({
                        select: <input type="checkbox" onClick={(e) => handleClickedCatalogueProducts(ele)} />,
                        productName: ele?.name,
                        logo: <>{
                            ele?.thumbnailDetails?.media?.length > 0 ? (
                                <img
                                    onClick={() => {
                                        handlePreview();
                                        setImage([...pignedImage, ...unpign]);
                                    }}
                                    src={[...pignedImage, ...unpign][0]}
                                    alt="logo"
                                    className="logoImage"
                                ></img>
                            ) : (
                                <span className="logoPreview">Image not uploaded</span>
                            )}</>,
                        srNo: ele?.srNo,
                        nfcTag: (
                            <span
                                style={{
                                    textTransform: "none",
                                }}
                            >
                                {ele?.xuid}
                            </span>
                        ),
                        status: (
                            <>
                                <div className="ProductFlowStatusParent">
                                    <div className="hr"></div>
                                    <div className="ProductFlowStatus">
                                        <div className="tooltipIcon">
                                            <div className="ps2 ">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            initialFlag && initialFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : initialFlag || initialFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Basic Information&nbsp;: </b> &nbsp;
                                                    {initialFlag && initialFlagWithMedia
                                                        ? "Basic Information is entered and Media uploaded"
                                                        : initialFlag
                                                            ? "Basic Information is entered and Media missing"
                                                            : initialFlagWithMedia
                                                                ? "Basic Information is missing,Media uploaded"
                                                                : "Basic Information and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps1 ">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            designFlag && designFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : designFlag || designFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Designing&nbsp;: </b> &nbsp;
                                                    {designFlag && designFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : designFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : designFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps3">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            dyingFlag && dyingFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : dyingFlag || dyingFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Dying&nbsp;: </b> &nbsp;
                                                    {dyingFlag && dyingFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : dyingFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : dyingFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps4">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            weavingFlag && weavingFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : weavingFlag || weavingFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Weaving&nbsp;: </b> &nbsp;
                                                    {weavingFlag && weavingFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : weavingFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : weavingFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps5 ">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            finishingFlag && finishingFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : finishingFlag || finishingFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Finishing&nbsp;: </b> &nbsp;
                                                    {finishingFlag && finishingFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : finishingFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : finishingFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ),
                        isTransfered: ele?.transfer === true ? "true" : "false",
                        transferTime: ele?.transferTime,
                        productOwner: ele?.productOwner,
                        designOwner: ele?.designOwner,
                        category: ele?.category?.name,
                        artform: ele?.artform?.name,
                        type: ele?.type?.name,
                        color: ele?.color,
                        subCategory: ele?.subcategory?.name,
                        weaveType: ele?.weavetype?.name,
                        orgSrNo: ele?.orgSrNo,
                        designNo: ele?.designNum,
                        // orgName: ele?.organizations[0]?.name,
                        createdAt: ele?.createdAt
                            ? moment(new Date(ele?.createdAt))
                                .subtract(5, "hours")
                                .subtract(30, "minutes")
                                .format("YYYY-MM-DD LT")
                            : "NA",
                        createdBy: ele?.createdBy ? ele?.createdBy?.role : "",
                    })

                })
                setCatalogueProductarryItem(catalogueItem)
            }
        })
    }
    const getAllProductData = () => {
        // setLoading(true)
        let payload = {
            offset: offsetProduct,
            limit: limit,

        }
        let productDataItem: any = [];
        props.getProducts(payload, (res: any) => {
            // setLoading(false)
            if (isArray(res?.data?.docs)) {
                totCntProduct = res?.data?.length;
                totPagesProduct = Math.ceil(totCntProduct / 10);
                fromValProduct = totCntProduct !== 0 ? offsetProduct * 10 + 1 : fromValProduct;
                toValProduct =
                    res?.data?.offset * 10 +
                    res?.data?.docs?.length;
                res?.data?.docs.forEach((ele: any, i: any) => {
                    let initialFlag =
                        ele?.srNo?.length > 0 ||
                        ele?.artform?.name?.length > 0 ||
                        ele?.type?.name?.length > 0 ||
                        ele?.category?.name?.length > 0 ||
                        ele?.subcategory?.name?.length > 0 ||
                        ele?.weavetype?.name?.length > 0 ||
                        ele?.typeOfSareeByDesign?.length > 0 ||
                        ele?.name?.length > 0 ||
                        ele?.colour?.length > 0 ||
                        ele?.tags?.length > 0 ||
                        ele?.brand?.length > 0 ||
                        ele?.weaver?.length > 0 ||
                        ele?.zari?.length > 0 ||
                        ele?.sareeWeight?.length > 0 ||
                        ele?.zariWeight?.length > 0 ||
                        ele?.purity?.length > 0 ||
                        ele?.yarnType?.length > 0 ||
                        ele?.loomNum?.length > 0 ||
                        ele?.xuid?.length > 0 ||
                        ele?.workingHours?.length > 0 ||
                        ele?.productionTimeInMonths?.length > 0 ||
                        ele?.silk?.length > 0 ||
                        ele?.handloom?.length > 0;
                    let initialFlagWithMedia = ele?.thumbnailDetails?.media?.length > 0;

                    let designFlag = ele?.designingDetails?.karigar?.name?.length > 0;
                    let designFlagWithMedia = ele?.designingDetails?.media?.length > 0;
                    let dyingFlag = ele?.dyingDetails?.karigar?.name?.length > 0;
                    let dyingFlagWithMedia = ele?.dyingDetails?.media?.length > 0;
                    let finishingFlag = ele?.finishingDetails?.karigar?.name?.length > 0;
                    let finishingFlagWithMedia = ele?.finishingDetails?.media?.length > 0;
                    let weavingFlag = ele?.weavingDetails?.karigar?.name?.length > 0;
                    let weavingFlagWithMedia = ele?.weavingDetails?.media?.length > 0;

                    let pignedImage: any = [];
                    let unpign: any = [];
                    if (ele?.thumbnailDetails?.media?.length > 0) {
                        ele?.thumbnailDetails?.media.map((ele: any, i: any) => {
                            if (ele?.pin === "1" || ele?.pin === 1 && ele?.show === "1" || ele?.show === 1) {
                                pignedImage.push(ele?.mediaURL);
                            } else if (ele?.show === "1" || ele?.show === 1) {
                                unpign.push(ele?.mediaURL);
                            }
                        });
                    }
                    productDataItem.push({
                        select: checkboxForProducts(ele, i),
                        productName: ele?.name,
                        logo: <>{
                            ele?.thumbnailDetails?.media?.length > 0 ? (
                                <img
                                    onClick={() => {
                                        handlePreview();
                                        setImage([...pignedImage, ...unpign]);
                                    }}
                                    src={[...pignedImage, ...unpign][0]}
                                    alt="logo"
                                    className="logoImage"
                                ></img>
                            ) : (
                                <span className="logoPreview">Image not uploaded</span>
                            )}</>,
                        srNo: ele?.srNo,
                        nfcTag: (
                            <span
                                style={{
                                    textTransform: "none",
                                }}
                            >
                                {ele?.xuid}
                            </span>
                        ),
                        status: (
                            <>
                                <div className="ProductFlowStatusParent">
                                    <div className="hr"></div>
                                    <div className="ProductFlowStatus">
                                        <div className="tooltipIcon">
                                            <div className="ps2 ">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            initialFlag && initialFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : initialFlag || initialFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Basic Information&nbsp;: </b> &nbsp;
                                                    {initialFlag && initialFlagWithMedia
                                                        ? "Basic Information is entered and Media uploaded"
                                                        : initialFlag
                                                            ? "Basic Information is entered and Media missing"
                                                            : initialFlagWithMedia
                                                                ? "Basic Information is missing,Media uploaded"
                                                                : "Basic Information and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps1 ">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            designFlag && designFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : designFlag || designFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Designing&nbsp;: </b> &nbsp;
                                                    {designFlag && designFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : designFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : designFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps3">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            dyingFlag && dyingFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : dyingFlag || dyingFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Dying&nbsp;: </b> &nbsp;
                                                    {dyingFlag && dyingFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : dyingFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : dyingFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps4">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            weavingFlag && weavingFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : weavingFlag || weavingFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Weaving&nbsp;: </b> &nbsp;
                                                    {weavingFlag && weavingFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : weavingFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : weavingFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tooltipIcon">
                                            <div className="ps5 ">
                                                <div
                                                    className="innerCircle"
                                                    style={{
                                                        backgroundColor:
                                                            finishingFlag && finishingFlagWithMedia
                                                                ? "rgb(119 216 119)"
                                                                : finishingFlag || finishingFlagWithMedia
                                                                    ? "rgb(84 115 182)"
                                                                    : "#FFF",
                                                    }}
                                                ></div>
                                                <span className="tooltipIcontext">
                                                    <b>Finishing&nbsp;: </b> &nbsp;
                                                    {finishingFlag && finishingFlagWithMedia
                                                        ? "Karigar is entered and Media uploaded"
                                                        : finishingFlag
                                                            ? "Karigar is entered and Media missing"
                                                            : finishingFlagWithMedia
                                                                ? "Karigar is missing,Media uploaded"
                                                                : "Karigar and media missing"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ),
                        isTransfered: ele?.transfer === true ? "true" : "false",
                        transferTime: ele?.transferTime,
                        productOwner: ele?.productOwner,
                        designOwner: ele?.designOwner,
                        category: ele?.category?.name,
                        artform: ele?.artform?.name,
                        type: ele?.type?.name,
                        color: ele?.color,
                        subCategory: ele?.subcategory?.name,
                        weaveType: ele?.weavetype?.name,
                        orgSrNo: ele?.orgSrNo,
                        designNo: ele?.designNum,
                        // orgName: ele?.organizations[0]?.name,
                        createdAt: ele?.createdAt
                            ? moment(new Date(ele?.createdAt))
                                .subtract(5, "hours")
                                .subtract(30, "minutes")
                                .format("YYYY-MM-DD LT")
                            : "NA",
                        createdBy: ele?.createdBy ? ele?.createdBy?.role : "",
                    })

                })
                setAllProductArry(productDataItem)
            }
        })
    }
    const checkboxForProducts = (ele: any, i: number) => {
        if (checkedAddProductData.includes(ele?.productUniqueId)) {
            return (
                <React.Fragment
                    key={i}

                >
                    {/* <CheckBoxItem
                        key={i}
                        item={ele}
                        selectedInvoices={checkedAddProductData}
                        SelectedInvoices={this.SelectedInvoices}
                        isDisabled={
                            ele?.isAvailableForTripCreation == false ||
                                ele?.isAvailableForTripCreation == ""
                                ? true
                                : false}
                    /> */}
                    {/* <Checkbox
                        name="bill3"
                        id="table-check"
                        value={ele?.productUniqueId}
                        defaultChecked
                        onClick={(e) => {
                            handleClickedAddProducts(ele)
                        }}
                        inputProps={{ "aria-label": "Checkbox demo" }}
                    /> */}
                </React.Fragment>

            )
        } else {
            return (<Checkbox
                name="bill3"
                id="table-check"
                value={ele?.productUniqueId}
                onClick={(e) => {
                    handleClickedAddProducts(ele)
                }}
                inputProps={{ "aria-label": "Checkbox demo" }}
            />)
        }

    }
    const handlePageChangeAllProducts = (event: any, value: any) => {
        offsetProduct = (value - 1) * 1;
        getAllProductData();
    };
    // preview image
    const handlePreview = () => {
        setOpenPreview(!openPreview);
    };
    // catalogue checked products
    const handleClickedCatalogueProducts = (data: any) => {
        let productId = data?.productUniqueId
        setCheckedProductData((prevCheckedItems: any) => {
            if (prevCheckedItems.includes(productId)) {
                return prevCheckedItems.filter((item: any) => item !== productId);
            } else {
                return [...prevCheckedItems, productId];
            }
        });

    }
    // add checked products
    const handleClickedAddProducts = (data: any) => {
        console.log(data, "test")
        let productId = data?.productUniqueId
        setCheckedAddProductData((prevCheckedItems: any) => {
            if (prevCheckedItems.includes(productId)) {
                return prevCheckedItems.filter((item: any) => item !== productId);
            } else {
                return [...prevCheckedItems, productId];
            }
        });

    }
    console.log(checkedAddProductData, "add")
    const handlePageChange = (event: any, value: any) => {
        offset = (value - 1) * 1;
        getAllCatalogueProductData();
    };
    // cards toggal
    const handleCatalogueProductToggal = () => {
        setCatalogueProductModal(!catalogueProductToggal)
    }
    const handleAllProductToggal = () => {
        setAllProductsToggal(!allProductToggal)
    }
    const handleResetCheckboxes = () => {
        setCheckedAddProductData([]);
        setCheckedProductData([]);
    }
    return (
        <>
            <h6 className='catalogue-h5'>Catalogue Name:{" " + location?.state?.catalogueName}</h6>

            <AdminCatalogueProducts
                {...props}
                catalogueId={catalogueId}
                catalogueProductToggal={catalogueProductToggal}
                handleCatalogueProductToggal={handleCatalogueProductToggal}
                openPreview={openPreview}
                handlePreview={handlePreview}
                image={image}
                checkedProductData={checkedProductData}
                offset={offset}
                limit={limit}
                totPages={totPages}
                totCnt={totCnt}
                toVal={toVal}
                fromVal={fromVal}
                handlePageChange={handlePageChange}
                getAllCatalogueProductData={getAllCatalogueProductData}
                data={catalogueProductarrayItem}
                loading={loading}
                handleResetCheckboxes={handleResetCheckboxes}
            />
            <AllProducts
                {...props}
                allProductToggal={allProductToggal}
                handleAllProductToggal={handleAllProductToggal}
                catalogueId={catalogueId}
                openPreview={openPreview}
                handlePreview={handlePreview}
                image={image}
                checkedProductData={checkedAddProductData}
                offset={offsetProduct}
                limit={limitProduct}
                totPages={totPagesProduct}
                totCnt={totCntProduct}
                toVal={toValProduct}
                fromVal={fromValProduct}
                handlePageChange={handlePageChangeAllProducts}
                getAllProductData={getAllProductData}
                data={allProductArry}
                loading={loading}
                getAllCatalogueProductData={getAllCatalogueProductData}
                handleResetCheckboxes={handleResetCheckboxes}
            />

        </>
    )
}

export default connect(null, {
    getProductsByCatAlogueId,
    getProducts
})(ViewCatalogProducts);