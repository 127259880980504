import React from 'react'
import SelectorField from "../../common/Components/SearchBarField/SelectorField";
import DateRangeSearchFilter from "../../common/Components/DateRangePicker/DateRangeSearchFilter";
import InputSearch from "../../common/Components/SearchBarField/InputSearch";
import { Button } from "reactstrap";
export const QuickFilter = (props: any) => {
    return (
        <>
            <div className="QuickFiltersHeader ps-3 pe-3">
                <div className="InputColP2">
                    <div>
                        <svg
                            onClick={() => props.openModalPopUp()}
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="34" height="34" rx="4" fill="#1A1A1A" />
                            <path
                                d="M17.5 12.5503V22.4497"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12.5503 17.5H22.4498"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>

                    <div>
                        {/* <Button className="ResetBtn"
         onClick={() => {
          props.handleResetData();
        }}
        >
          Reset
        </Button> */}

                    </div>
                </div>
                <div className="InputColP addIcon">
                    <svg
                        onClick={() => props.openModalPopUp()}
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="34" height="34" rx="4" fill="#1A1A1A" />
                        <path
                            d="M17.5 12.5503V22.4497"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12.5503 17.5H22.4498"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                {/* <div className="InputColP">
          <InputSearch
            {...props}
            id={"Artform"}
            name="artform1"
            placeholder="Artform"
            value={props.filters?.artform}
            onChange={(e: any) => {
              props.handleChangeFilter({
                name: "artform",
                value: e.target.value,
              });
            }}
            onKeyDown={(event: any) => props.handleKeyDown(event)}
          />
        </div>
        <div className="InputColP">
          <SelectorField
            {...props}
            name="creator"
            placeholder="Creator"
            onChange={(e: any) =>
              props.handleChangeFilter({
                name: "creator",
                value: e,
              })
            }
            value={props.filters?.creator}
            options={props.creatorOptions}
            onFocus={(e: any) => {
              props.autoFocus({
                name: "creator",
              });
            }}
            onInputChange={(e: any) =>
              props.handleOnInputChange({
                name: "creator",
                value: e,
              })
            }
          />
        </div>
        <div className="InputColP">
          <DateRangeSearchFilter
            value={props.filterDateValue}
            onChange={(date: any) => {
              props.handleChangeDate(date);
            }}
          />
        </div>
        <div className="InputColP1 searchIcon">
        <Button className="ResetBtn"
         onClick={() => {
          props.handleResetData();
        }}
        >
          Reset
        </Button>
       
        </div> */}
            </div>
        </>
    )
}
