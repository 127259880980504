import {types } from "./actionTypes";

// creating action
export const getCatAlogue = (filters:any, callback:any) =>{
    return {
        type:types.GET_CATALOGUE,
        filters,
        callback
    }
}
export const createCatAlogue = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_CATALOGUE,
        payload,
        callback
    }
}
export const updateCatAlogue = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_CATALOGUE,
        payload,
        callback
    }
}
export const deleteCatAlogue = (id:any, callback:any) =>{
    return {
        type:types.DELETE_CATALOGUE,
        id,
        callback
    }
}
export const getProductsByCatAlogueId = (filters:any, catalogueId:any, callback:any) =>{
    return {
        type:types.GET_ALL_PRODUCT_WITH_CATALOGUE_ID,
        filters,
        catalogueId,
        callback
    }
}
export const addProductByCatAlogueId = (payload:any, catalogueId:any, callback:any) =>{
    return {
        type:types.ADD_PRODUCT_WITH_CATALOGUE_ID,
        payload,
        catalogueId,
        callback
    }
}
export const deleteProductInCatAlogue = (payload:any,catalogueId:any, callback:any) =>{
    return {
        type:types.REMOVE_PRODUCT_FROM_CATALOGUE,
        payload,
        catalogueId,
        callback
    }
}