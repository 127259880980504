import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { isArray } from 'lodash';
import { Card, Button } from 'reactstrap';
//components
import ProductImagePreView from "../products/ProductImagePreView";
import Index from "../../common/Components/Index";
import Loader from '../../common/Components/Loader/Loader';
import { HeaderCatalogueProductData } from './Header';
import useProductDataLoop from './useProductDataLoop';
import notifications from '../../common/Components/Notifications/Notification';

//actions
import {
addProductByCatAlogueId
} from "../../Store/catelogue/actions";
//images
import arrowUpIcon from "./assets/images/icons8-arrow-up-48.png";
import arrowDownIcon from "./assets/images/icons8-expand-arrow-26.png";
// variables
var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
let allProduct: any = [];
let data: any = [];
const AllProducts = (props: any) => {
    let {
        allProductToggal,
        handleAllProductToggal,
        catalogueId,
        openPreview,
        handlePreview,
        image,
        checkedProductData,
        offset,
        limit,
        totPages,
        totCnt,
        toVal,
        fromVal,
        handlePageChange,
        getAllProductData,
        data,
        loading,
        getAllCatalogueProductData,
        handleResetCheckboxes
    } = props
  
   
    const addProductsIntoCatalogue = (checkedProductData: any) => {
        props.addProductByCatAlogueId({
          productIds:checkedProductData
        }, catalogueId, (res: any) => {
               if (res.success) {
                    notifications({
                        title: "Success!",
                        message: res?.message,
                        type: "success",
                    });
                   getAllProductData();
                   getAllCatalogueProductData();
                   handleResetCheckboxes();
                } else {
                    notifications({
                        title: "Error!",
                        message: res?.message,
                        type: "danger",
                    });
                }
      })
    }
    return (
        <>
            {openPreview && (
                <ProductImagePreView
                    {...props}
                    openModal={openPreview}
                    closeModal={handlePreview}
                    images={image}
                />
            )}
            <Card className='catalogue-product-card'>
                <div className='catalogue-product'>
                    <div>
                        All Products
                        <Button className='catalogue-btn'
                            disabled={checkedProductData?.length <=0 ? true :false}
                            onClick={()=>addProductsIntoCatalogue(checkedProductData)}
                        >Add Products into Catalogue</Button>

                    </div>
                    <div>
                        {
                            allProductToggal ?
                                <img className='cursor-pointer'
                                    onClick={() => {
                                        handleAllProductToggal()
                                    }}
                                    src={arrowUpIcon} width={20} height={20} alt="Arrow Up" />
                                : <img className='cursor-pointer'
                                    onClick={() => {
                                        handleAllProductToggal()
                                    }}
                                    src={arrowDownIcon} width={20} height={20} alt="Arrow Up" />
                        }

                    </div>
                </div>

            </Card>
            {
                allProductToggal &&
                <>
                    {
                        data?.length >= 0 ?
                            <>
                                <Index
                                    {...props}
                                    data={data}
                                    headerData={HeaderCatalogueProductData}
                                    limit={limit}
                                    handleChange={handlePageChange}
                                    offset={offset}
                                    totPages={totPages}
                                    loading={loading}
                                    fromVal={fromVal}
                                    toVal={toVal}
                                    totCnt={totCnt}
                                />

                            </> : ""
                    }
                </>
            }


        </>
    )
}

export default connect(null, {
    addProductByCatAlogueId
})(AllProducts);