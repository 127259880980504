import { types } from "./actionTypes";
const initialState = {
  getCategoryDetails: null,
  updateCategoryDetails: null,
  createCategoryDetails: null,
  deleteCategoryDetails: null,
  getCategoryFilterDetails: null,
  getProductsWithCatAlogue: null,
};
const catAlogueReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_CATALOGUE_SUCCESS:
      state = {
        ...state,
        getCategoryDetails: action.payload,
      };
      return state;
    case types.CREATE_CATALOGUE_SUCCESS:
      state = {
        ...state,
        createCategoryDetails: action.payload,
      };
      return state;
    case types.UPDATE_CATALOGUE_SUCCESS:
      state = {
        ...state,
        updateCategoryDetails: action.payload,
      };
      return state;
    case types.DELETE_CATALOGUE_SUCCESS:
      state = {
        ...state,
        deleteCategoryDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default catAlogueReducer;
