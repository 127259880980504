import React, { useEffect, useState } from 'react'
import HeaderEcomerce from './HeaderEcomerce';
import { useLocation } from 'react-router-dom';

import { connect } from "react-redux";
import { baseImageCovert, baseImageCovertForLogoPin } from "../../products/baseImageConver";
// actions
import { getProducts } from "../../../Store/products/actions";
import { isArray } from 'lodash';
import CatalogueProductInfo from './CatalogueProductInfo';
const ProductInformation = (props: any) => {
 
  const ProductId: any = window.location?.pathname.split("/")[2];
  const [form, setForm]: any = useState({
    productUniqueId: "",
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    productName: "",
    designer: "",
    designerMedia: [],
    weaver: "",
    weaverMedia: [],
    dyer: "",
    dyerMedia: [],
    finisher: "",
    finisherMedia: [],
    profileURL: "",
    tags: [],
    variants: [],
    yarnType: "",
    workingHours: "",
    loomNum: "",
    color: "",
    productOwner: "",
    sellPrice: "",
    costPrice: "",
    designNum: "",
  });
  useEffect(() => {
    if (ProductId) {
      getAllData();
    }
  }, [ProductId]);
  const getAllData = () => {
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          let editDetails: any = res?.data?.docs[0];
          let variantArry: any = [];
          let variantBaseDetails = {
            color: editDetails?.color,
            additionalImages: editDetails?.additionalImages?.length
              ? editDetails?.additionalImages
              : [],
          };
          variantArry.push(variantBaseDetails);
          setForm({
            _id: editDetails?._id,
            org: editDetails?.organizations?.length
              ? editDetails?.organizations[0]?.name
              : "",
            productOwner: editDetails?.productOwner,

            productUniqueId: editDetails?.productUniqueId,
            artform: editDetails?.artform?.name,
            type: editDetails?.type?.name,
            category: editDetails?.category?.name,
            subCategory: editDetails?.subcategory?.name,
            weaveType: editDetails?.weavetype?.name,
            productName: editDetails?.name,
            designer: editDetails?.designingDetails?.karigar?.name,
            designerMedia: editDetails?.designingDetails?.media?.length
              ? baseImageCovert(editDetails?.designingDetails?.media)
              : "",
            weaver: editDetails?.weavingDetails?.karigar?.name,
            weaverMedia: editDetails?.weavingDetails?.media?.length
              ? baseImageCovert(editDetails?.weavingDetails?.media)
              : "",
            dyer: editDetails?.dyingDetails?.karigar?.name,
            dyerMedia: editDetails?.dyingDetails?.media?.length
              ? baseImageCovert(editDetails?.dyingDetails?.media)
              : "",
            finisher: editDetails?.finishingDetails?.karigar?.name,
            finisherMedia: editDetails?.finishingDetails?.media?.length
              ? baseImageCovert(editDetails?.finishingDetails?.media)
              : "",
            profileURL: editDetails?.thumbnailDetails?.media?.length
              ? baseImageCovertForLogoPin(editDetails?.thumbnailDetails?.media)
              : editDetails?.thumbnailDetails === null &&
                editDetails?.thumbnail?.length > 0
                ? baseImageCovertForLogoPin([
                  {
                    mediaURL: editDetails?.thumbnail,
                    show: "1",
                    pin: "1",
                  },
                ])
                : [],
            tags: editDetails?.tags ? editDetails?.tags : "",
            variants:
              editDetails?.varients?.length > 0
                ? [...variantArry, ...editDetails?.varients]
                : [...variantArry],
            //new
            nameOfBrand: editDetails?.brand,
            nameOfWeaver: editDetails?.weaver,
            handloomMark: editDetails?.handloom === "true" ? true : false,
            silkMark: editDetails?.silk === "true" ? true : false,
            zariUsed: { label: editDetails?.zari, value: editDetails?.purity },
            weightOfSaree: editDetails?.sareeWeight,
            weightOfZariUsed: editDetails?.zariWeight,
            purity: editDetails?.purity,
            madeInIndia: editDetails?.madeIn === "true" ? true : false,
            yarnType: editDetails?.yarnType,
            workingHours: editDetails?.workingHours,
            loomNum: editDetails?.loomNum,
            xuid: editDetails?.xuid,
            SrNo: editDetails?.srNo,
            weavingCenter: editDetails?.weavingCenter || form?.weavingCenter,
            typeOfSareeByDesign: editDetails?.typeOfSareeByDesign,
            productionTimeInMonths: editDetails?.productionTimeInMonths,
            color: editDetails?.color,
            sellPrice: editDetails?.sellPrice,
            costPrice: editDetails?.costPrice,
            designNum: editDetails?.designNum,
          });
        }
      }
    );
  };
  console.log("product", form)
  return (
    <>
      <HeaderEcomerce />
      <br /> <br /> <br /> <br />
      <CatalogueProductInfo  form={form}/>

    </>)
}
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getProducts,
})(ProductInformation);