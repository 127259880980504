import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, } from "reactstrap";
import Select from "react-select";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
import { connect } from "react-redux";
//components
import { AddIcon } from '../../common/Components/Icons/AddIcon';
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import {
    getCatAlogue,
    addProductByCatAlogueId
} from "../../Store/catelogue/actions";
import { isArray } from 'lodash';
const catalogueOptions: any = [];
const CatalogueListModal = (props: any) => {
    const { openModal, closeModal, catalogueProduct, getProductData, handleOpenCatalogue } = props;
    const [loading, setLoading]: any = useState(false)
    const [catalogueName, setCatalogueName]: any = useState(null);
    const [Error, setError]: any = useState({
        name: "",
        description: ""
    });
    useEffect(() => {
        if (openModal) {
            getAllData();
        }
    }, [openModal])
    const getAllData = () => {
        setLoading(true)
        let payload = {
            offset: 0,
            limit: 10
        }
        props.getCatAlogue(payload, (res: any) => {
            let data: any = [];
            setLoading(false)

            if (isArray(res?.data?.docs)) {

                res?.data?.docs.forEach((ele: any, i: any) => {
                    catalogueOptions.push({
                        label: ele?.name + " " + ele?.description,
                        value: ele?._id
                    })
                })
            }
        })
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true)
        props.addProductByCatAlogueId({
            productIds: [
                catalogueProduct
            ]
        }, catalogueName?.value, (res: any) => {
            setLoading(false);
            if (res.success) {
                notifications({
                    title: "Success!",
                    message: res?.message,
                    type: "success",
                });
                props.closeModal();
                getProductData();
            } else {
                notifications({
                    title: "Error!",
                    message: res?.message,
                    type: "danger",
                });
            }
        })
    }
    return (
        <Modal
            isOpen={openModal}
            toggle={closeModal}
            size="lg"
            className="CatalogueModal"
            fade={false}
        >
            <ModalHeader>
                <div className="Heading">
                    <h3>Add Product Into Catalogue</h3>
                    <ModalCloseIcon {...props} closeModal={closeModal} />
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="AddCatalogue">
                    <form className="form">
                        {loading ? (
                            <div className="LoaderDivModal">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div className="formDiv">
                                    <Label>
                                        Name<span>*</span>
                                    </Label>
                                    <div className="formDivSub">
                                        <Select
                                            className="React_selector text-white"
                                            classNamePrefix="react-select"
                                            placeholder="Select Catalogue Name"
                                            isSearchable
                                            name="Type"
                                            id="Type"
                                            value={catalogueName}
                                            options={catalogueOptions}
                                            onChange={(e: any) => {
                                                setCatalogueName(e)
                                            }}

                                        />
                                        <div className="DivSep"></div>
                                        <div className="formAddDiv">
                                            <AddIcon openModal={handleOpenCatalogue} />
                                        </div>
                                    </div>

                                    {Error.name && !catalogueName ? (
                                        <p className="RequiredField">{Error?.name} </p>
                                    ) : (
                                        ""
                                    )}
                                </div>

                            </>

                        )}
                        <Button className="artButton"
                            onClick={(e) => handleSubmit(e)}>
                            {loading ? "Processing" : "Save"}
                        </Button>                    </form>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default connect(null, {
    getCatAlogue,
    addProductByCatAlogueId
})(CatalogueListModal);
