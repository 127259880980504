interface Column {
  label: string;
  field: string;
  sort?: boolean | string;
  width: number;
}

interface HeaderData {
  columns: Column[];
}

export const HeaderData: HeaderData = {
  columns: [
    {
      label: "Name",
      field: "name",
      sort: false,
      width: 200,
    },
    {
      label: "Description",
      field: "description",
      sort: false,
      width: 200,
    },
    {
      label: "Copy to URL",
      field: "copyURL",
      sort: false,
      width: 100,
    },
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
export const HeaderCatalogueProductData: HeaderData = {
  columns: [

    {
      label: " ",
      field: "select",
      sort: "disabled",
      width: 1,
    },

    {
      label: "SrNo",
      field: "orgSrNo",
      sort: true,
      width: 2,
    },
    {
      label: "DesignNo",
      field: "designNo",
      sort: true,
      width: 2,
    },
    {
      label: "Image",
      field: "logo",
      sort: false,
      width: 1,
    },
    {
      label: "Product Name",
      field: "productName",
      sort: true,
      width: 3,
    },
    {
      label: "Status",
      field: "status",
      sort: false,
      width: 3,
    },
    {
      label: "Color",
      field: "color",
      sort: false,
      width: 1,
    },
    // {
    //   label: "Creator Oganization",
    //   field: "orgName",
    //   sort: true,
    //   width: 3,
    // },

    {
      label: "Design Owner",
      field: "designOwner",
      sort: true,
      width: 3,
    },
    {
      label: "Product Owner",
      field: "productOwner",
      sort: true,
      width: 3,
    },
    {
      label: "HKPtagNo",
      field: "srNo",
      sort: true,
      width: 2,
    },
    {
      label: "HKP Tag",
      field: "nfcTag",
      sort: true,
      width: 2,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 2,
    }
  ],
};


