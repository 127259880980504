import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";
//medthods
import {
  postApi,
  getApi,
  deleteApi,
  putApi
} from "../../helpers/auth_helpers";

// workers
function* workerGetCatalogue({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + 
      "/catalouge/catalogues?offset=" +
      offset +
      "&limit=" +
      limit;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_CATALOGUE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
} 
function* workerCreateCatalogue({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/catalouge/createCatalogue";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_CATALOGUE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateCatalogue({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/catalouge/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_CATALOGUE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteCatalogue({ id, callback }: any) {
    try {
      const url: string = process.env.REACT_APP_SERVERURL + `/catalouge/${id}/delete`;
      const response: AxiosResponse = yield call(deleteApi, url);
      callback(response);
      yield put({
        type: types.DELETE_CATALOGUE_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
}
  //products in catalogue
function* workerGetProductsByCatalogueId({ filters, catalogueId,callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      `/catalouge/${catalogueId}/products?offset=` +
      offset +
      "&limit=" +
      limit;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_ALL_PRODUCT_WITH_CATALOGUE_ID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
} 
function* workerAddProductsIntoCatalogue({ payload,catalogueId, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + `/catalouge/${catalogueId}/addProducts`;
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_CATALOGUE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteProductFromCatalogue({ payload,catalogueId, callback }: any) {
    try {
      const url: string = process.env.REACT_APP_SERVERURL + `/catalouge/${catalogueId}/removeProducts`;
      const response: AxiosResponse = yield call(postApi, url,payload);
      callback(response);
      yield put({
        type: types.DELETE_CATALOGUE_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }
// watchers
export function* watchGetCatalogue() {
  yield takeEvery(types.GET_CATALOGUE, workerGetCatalogue);
}
export function* watchCreateCatalogue() {
  yield takeEvery(types.CREATE_CATALOGUE, workerCreateCatalogue);
}
export function* watchUpdateCatalogue() {
  yield takeEvery(types.UPDATE_CATALOGUE, workerUpdateCatalogue);
}
export function* watchDeleteCatalogue() {
    yield takeEvery(types.DELETE_CATALOGUE, workerDeleteCatalogue);
}
//products with catalogue
  export function* watchGetProductsByCatalogueId() {
  yield takeEvery(types.GET_ALL_PRODUCT_WITH_CATALOGUE_ID, workerGetProductsByCatalogueId);
}
export function* watchAddProductsIntoCatalogue() {
  yield takeEvery(types.ADD_PRODUCT_WITH_CATALOGUE_ID, workerAddProductsIntoCatalogue);
}
export function* watchDeleteProductFromCatalogue() {
    yield takeEvery(types.REMOVE_PRODUCT_FROM_CATALOGUE, workerDeleteProductFromCatalogue);
  }

function* CatalogueSaga() {
  yield all([fork(watchGetCatalogue)]);
  yield all([fork(watchCreateCatalogue)]);
  yield all([fork(watchUpdateCatalogue)]);
  yield all([fork(watchDeleteCatalogue)]);
  yield all([fork(watchGetProductsByCatalogueId)]);
  yield all([fork(watchAddProductsIntoCatalogue)]);
  yield all([fork(watchDeleteProductFromCatalogue)]);
}
export default CatalogueSaga;
