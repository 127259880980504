import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

// Components
import Index from "../../common/Components/Index";
import notifications from '../../common/Components/Notifications/Notification';
import { QuickFilter } from './QuickFilter';
import DeleteConfirm from '../../common/Components/DeleteConfirm';
import CatalogueModal from './CatalogueModal';
//files
import { HeaderData } from './Header';
// Actions
import {
    getCatAlogue,
    deleteCatAlogue,
} from "../../Store/catelogue/actions";

// Icons
import { FaRegCopy } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";

// Types
interface CatalogueItem {
    _id: string;
    name: string;
    description: string;
}

interface ResponseData {
    docs: CatalogueItem[];
    offset: number;
    length: number;
}

interface CatalogProps {
    getCatAlogue: (payload: { offset: number; limit: number }, callback: (res: any) => void) => void;
    deleteCatAlogue: (id: string, callback: (res: any) => void) => void;
}

// Constants
const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 10;

const Catalog: React.FC<CatalogProps> = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [catalogData, setCatalogData] = useState<any[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [deleteCataloge, setDeleteCatalogData] = useState<CatalogueItem | null>(null);
    const [editCatalogueData, setEditCatalogueData] = useState<CatalogueItem | null>(null);
    const [openCreateCatalogueModal, setOpenCreateCatalgueModal] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<any>({});
    const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

    useEffect(() => {
        fetchCatalogData();
    }, []);

    const fetchCatalogData = () => {
        setLoading(true);
        const offset = Number(localStorage.getItem("offsetForCatalogue")) || DEFAULT_OFFSET;
        const payload = { offset, limit: DEFAULT_LIMIT };

        props.getCatAlogue(payload, (res: any) => {
            setResponseData(res?.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (responseData?.docs) {
            const data = responseData.docs.map((ele: any, i: number) => ({
                name: (
                    <span
                        onClick={() => history.push(`/pre-view-catalogue-products/${ele._id}`, { productDetails: ele })}>
                        {ele.name}
                    </span>
                ),
                description: ele.description,
                copyURL: (
                    <button onClick={() => handleCopyToClipboard(ele._id, i)}>
                        {copiedIndex === i ? (
                            <> <FaRegCheckCircle /> <span style={{ color: 'green' }}>Copied!</span></>
                        ) : (
                            <><FaRegCopy /> Copy to clipboard</>
                        )}
                    </button>
                ),
                actions: renderActions(ele),
            }));
            setCatalogData(data);
        }
    }, [responseData, copiedIndex]);

    const handleCopyToClipboard = (id: string, index: number) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_DEV_URL}/catalogue-products/${id}`)
            .then(() => {
                setCopiedIndex(index);
                setTimeout(() => setCopiedIndex(null), 1500);
            })
            .catch(err => console.error('Failed to copy: ', err));
    };

    const renderActions = (ele: CatalogueItem) => (
        <div className="d-flex align-items-center tableBtns">
            <span className="btns" onClick={() => history.push(`/pre-view-catalogue-products/${ele._id}`, { catalogueName: ele.name })}>
                Pre-View E-commerce
            </span>
            <span className="actionLineHeight me-2"></span>
            <span className="btns" onClick={() => history.push(`/view-catalogue-products/${ele._id}`, { catalogueName: ele.name })}>
                View Catalog Product
            </span>
            <span className="actionLineHeight me-2"></span>
            <span className="btns" onClick={() => openCreateOrEditCatalogueModal(ele)}>
                Edit
            </span>
            <span className="actionLineHeight"></span>
            <span className="btns" onClick={() => confirmDelete(ele)}>
                Delete
            </span>
        </div>
    );

    const confirmDelete = (ele: CatalogueItem) => {
        setOpenDeleteModal(true);
        setDeleteCatalogData(ele);
    };

    const deleteDataWithConfirm = (deleteData: CatalogueItem) => {
        props.deleteCatAlogue(deleteData._id, (res: any) => {
            notifications({
                title: res.success ? "Success!" : "Error!",
                message: res?.message,
                type: res.success ? "success" : "danger",
            });
            if (res.success) fetchCatalogData();
        });
    };

    const openCreateOrEditCatalogueModal = (data: CatalogueItem | null = null) => {
        setEditCatalogueData(data);
        setOpenCreateCatalgueModal(!openCreateCatalogueModal);
    };

    return (
        <>
            <CatalogueModal
                {...props}
                openModal={openCreateCatalogueModal}
                closeModal={openCreateOrEditCatalogueModal}
                editData={editCatalogueData}
                getAllData={fetchCatalogData}
            />
            <DeleteConfirm
                {...props}
                warningModal={openDeleteModal}
                warningToggle={() => setOpenDeleteModal(false)}
                deleteDataWithConfirm={deleteDataWithConfirm}
                deleteData={deleteCataloge}
                getAllData={fetchCatalogData}
                name={"Catalog Name"}
            />
            <QuickFilter
                {...props}
                openModalPopUp={openCreateOrEditCatalogueModal}
                getAllData={fetchCatalogData}
            />
            <Index
                {...props}
                data={catalogData}
                headerData={HeaderData}
                limit={DEFAULT_LIMIT}
                handleChange={(event: any, value: any) => {
                    const newOffset = (value - 1) * DEFAULT_LIMIT;
                    localStorage.setItem("offsetForCatalogue", newOffset.toString());
                    fetchCatalogData();
                }}
                loading={loading}
                fromVal={responseData?.offset * DEFAULT_LIMIT + 1}
                toVal={responseData?.offset * DEFAULT_LIMIT + responseData?.docs?.length}
                totCnt={responseData?.length}
                offset={DEFAULT_OFFSET}
            />
        </>
    );
};

export default connect(null, {
    getCatAlogue,
    deleteCatAlogue,
})(Catalog);
