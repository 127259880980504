import { Card } from "reactstrap";
import { Link } from "react-router-dom";
// components Icons
import DashboardIcon from "../Components/Icons/DashboardIcon";
import OrganizationIcon from "../Components/Icons/OrganizationIcon";
import UserIcon from "../Components/Icons/UsersIcon";
import ArtformIcon from "../Components/Icons/ArtformIcon";
import TypeIcon from "../Components/Icons/TypeIcon";
import CategoryIcon from "../Components/Icons/CategoryIcon";
import SubCategoryIcon from "../Components/Icons/SubCategoryIcon";
import ProductIcon from "../Components/Icons/ProductIcon";
import WeaveTypeIcon from "../Components/Icons/WeaveTypeIcon";
import CatalogIcon from "../../assets/images/icons8-catalog-50.png";

const SideMiniCollaspe = (props: any) => {
  const { logo, applicationUrl, location, handleClick, authUser } = props;

  return (
    <Card className="verticlemenuCardMini">
      <div className="LogoImage">
        {logo ? (
          <img
            src={logo}
            width="190px"
            height={"80px"}
            alt="Organization Logo"
          />
        ) : (
          <img
            src={applicationUrl}
            width="190px"
            height={"80px"}
            alt="Company Logo"
          />
        )}
      </div>
      <div className="parentListMini">
        <Link to="/dashboard">
          <div
            className={
              location?.pathname === "/dashboard"
                ? "verticleNameP verticleNamePOnclick"
                : "verticleNameP"
            }
            onClick={() =>
              handleClick({
                name: "dashboard",
              })
            }
          >
            <DashboardIcon />
          </div>
        </Link>
        <Link to="/products">
          <div
            className={
              location?.pathname === "/products" ||
              location?.pathname === "/createProduct" ||
              location.pathname.split("/")[1] === "view-product" ||
              location.pathname.split("/")[1] === "pre-viewProduct" ||
              location.pathname.split("/")[1] === "edit-product"
                ? "verticleNameP verticleNamePOnclick"
                : "verticleNameP"
            }
            onClick={() => {
              handleClick({
                name: "products",
              });
            }}
          >
            <ProductIcon />
          </div>
        </Link>
        {authUser?.users?.role === "SUPER_ADMIN" ? (
          // ||
          // (authUser?.users?.role === "ORGANIZATION_ADMIN" &&
          //   process.env.REACT_APP_PERMISSIONS_ORG_ADMIN === "true") ||
          // (authUser?.users?.role === "ORGANIZATION_MANAGER" &&
          //   process.env.REAC_APP_PERMISSIONS_ORG_MANAGER === "true")
          <>
            <Link to="/organisation">
              <div
                className={
                  location?.pathname === "/organisation"
                    ? "verticleNameP verticleNamePOnclick"
                    : "verticleNameP"
                }
                onClick={() =>
                  handleClick({
                    name: "organisation",
                  })
                }
              >
                <OrganizationIcon />
              </div>
            </Link>
          </>
        ) : (
          ""
        )}
        {/* {(authUser?.users?.role === "ORGANIZATION_ADMIN" ||
          authUser?.users?.role === "ORGANIZATION_MANAGER") &&
        authUser?.users?.organizationResponse?.organizationType ===
          "Design Agency" ? (
          <Link to="/manufactures">
            <div
              className={
                location?.pathname === "/manufactures"
                  ? "verticleNameP verticleNamePOnclick"
                  : "verticleNameP"
              }
            >
              <OrganizationIcon />
            </div>
          </Link>
        ) : (
          ""
        )} */}
         {authUser?.users?.role === "ORGANIZATION_ADMIN" ||
        authUser?.users?.role === "ORGANIZATION_MANAGER" ? (
          //   &&
          // authUser?.users?.organizationResponse?.organizationType ===
          //   "Manufacturer"
          <Link to="/catalogue">
            <div
              className={
                location?.pathname === "/catalogue"
                  ? "verticleNameP verticleNamePOnclick"
                  : "verticleNameP"
              }
            >
            <img src={CatalogIcon} width="25px" height="25px" alt="catalogue" />
            </div>
          </Link>
        ) : (
          ""
        )}
        {authUser?.users?.role === "ORGANIZATION_ADMIN" ||
        authUser?.users?.role === "ORGANIZATION_MANAGER" ? (
          //   &&
          // authUser?.users?.organizationResponse?.organizationType ===
          //   "Manufacturer"
          <Link to="/party">
            <div
              className={
                location?.pathname === "/party"
                  ? "verticleNameP verticleNamePOnclick"
                  : "verticleNameP"
              }
            >
              <OrganizationIcon />
            </div>
          </Link>
        ) : (
          ""
        )}
        <Link to="/users">
          <div
            className={
              location?.pathname === "/users" ||
              location?.pathname === "/createUser" ||
              location.pathname.split("/")[1] === "editUser" ||
              location.pathname.split("/")[1] === "viewUser"
                ? "verticleNameP verticleNamePOnclick"
                : "verticleNameP"
            }
            onClick={() =>
              handleClick({
                name: "users",
              })
            }
          >
            <UserIcon />
          </div>
        </Link>
        {authUser?.users?.role === "SUPER_ADMIN" ||
        (authUser?.users?.role === "ORGANIZATION_ADMIN" &&
          process.env.REACT_APP_PERMISSIONS_ORG_ADMIN === "true") ||
        (authUser?.users?.role === "ORGANIZATION_MANAGER" &&
          process.env.REAC_APP_PERMISSIONS_ORG_MANAGER === "true") ? (
          <>
            <Link to="/artform">
              <div
                className={
                  location?.pathname === "/artform"
                    ? "verticleNameP verticleNamePOnclick"
                    : "verticleNameP"
                }
                onClick={() =>
                  handleClick({
                    name: "artform",
                  })
                }
              >
                <ArtformIcon />
              </div>
            </Link>
            <Link to="/type">
              <div
                className={
                  location?.pathname === "/type"
                    ? "verticleNameP verticleNamePOnclick"
                    : "verticleNameP"
                }
                onClick={() =>
                  handleClick({
                    name: "type",
                  })
                }
              >
                <TypeIcon />
              </div>
            </Link>
            <Link to="/category">
              <div
                className={
                  location?.pathname === "/category"
                    ? "verticleNameP verticleNamePOnclick"
                    : "verticleNameP"
                }
                onClick={() =>
                  handleClick({
                    name: "category",
                  })
                }
              >
                <CategoryIcon />
              </div>
            </Link>
            <Link to="/sub-category">
              <div
                className={
                  location?.pathname === "/sub-category"
                    ? "verticleNameP verticleNamePOnclick"
                    : "verticleNameP"
                }
                onClick={() =>
                  handleClick({
                    name: "sub-category",
                  })
                }
              >
                <SubCategoryIcon />
              </div>
            </Link>
            <Link to="/weave-type">
              <div
                className={
                  location?.pathname === "/weave-type"
                    ? "verticleNameP verticleNamePOnclick"
                    : "verticleNameP"
                }
                onClick={() =>
                  handleClick({
                    name: "weave-type",
                  })
                }
              >
                <WeaveTypeIcon />
              </div>
            </Link>
          </>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export default SideMiniCollaspe;
