import React from "react";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
//components
import Loader from "./Loader/Loader";
import SortAssendingicon from "./Icons/SortAssendingicon";
import SortDescendingIcon from "./Icons/SortDescendingIcon";
let dataSet: any = [];
let processedData: any = [];
const Index = (props: any) => {
  const location: any = useLocation();
  const [selectSortFields, setSelectSortFields]: any = React.useState({
    sort: "",
    fieldName: "",
  });
  let headerData = props.headerData;

  const binder = (dataItem: any, index: any) => {
    let dataSetItem: any = [];
    let headers = headerData?.columns;
    Object.keys(dataItem).map((key, index) => {
      if (dataSet?.length <= 10 || props?.limit > 0)
        if (headers)
          headers.forEach((headItem: any, headIndex: any) => {
            if (headItem.field === key) {
              dataSetItem[headIndex] = dataItem[key];
            }
          });
      return true;
    });
    dataSet[index] = dataSetItem;
    processedData = dataSet;
    // setProcessedData(dataSet)
  };
  var formatter = (data: any) => {
    dataSet = [];
    data.map((dataItem: any, index: any) => {
      binder(dataItem, index);
      return true;
    });
  };
  let columnWidthArray: any = [];
  headerData?.columns.forEach((item: any) => {
    columnWidthArray.push(item.width);
  });
  if (props.data?.length > 0) {
    formatter(props.data);
  }
  return (
    <>
      {props?.loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <>
          <div
            className={
              headerData?.columns?.length >= 11
                ? "tableParent1"
                : "tableParent"
            }
            style={
              {
                // height:props.data?.length >=8 ? "auto":"700px"
              }
            }
          >
            <div>
              <Row className={"tableHeaderRow"}>
                {headerData?.columns?.map((headItem: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <Col
                        className=""
                        key={index}
                        style={{
                          flex: columnWidthArray[index],
                          textAlign:
                            location?.pathname.split(":")[0] === "/products"
                              ? headItem.label === "Actions"
                                ? "center"
                                : "left"
                              : "left",
                        }}
                      >
                        {headItem.label}{" "}
                        {headItem?.sort === true ? (
                          <>
                            {selectSortFields?.sort == "asc" &&
                              selectSortFields.fieldName == headItem.field ? (
                              <SortDescendingIcon
                                handleSort={() => {
                                  props.handleSort({
                                    name: headItem.field,
                                    sort: "desc",
                                  });
                                  setSelectSortFields({
                                    sort: "desc",
                                    fieldName: headItem.field,
                                  });
                                }}
                              />
                            ) : (
                              <>
                                {selectSortFields?.sort == "desc" &&
                                  selectSortFields.fieldName == headItem.field ? (
                                  <SortAssendingicon
                                    handleSort={() => {
                                      props.handleSort({
                                        name: headItem.field,
                                        sort: "asc",
                                      });
                                      setSelectSortFields({
                                        sort: "asc",
                                        fieldName: headItem.field,
                                      });
                                    }}
                                  />
                                ) : (
                                  <SortAssendingicon
                                    handleSort={() => {
                                      props.handleSort({
                                        name: headItem.field,
                                        sort: "asc",
                                      });
                                      setSelectSortFields({
                                        sort: "asc",
                                        fieldName: headItem.field,
                                      });
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </React.Fragment>
                  );
                })}
              </Row>
              {props.data?.length > 0 && processedData?.length > 0 ? (
                processedData.map((Item: any, index: any) => {
                  return (
                    <div className="tableBodyDeatils" key={index}>
                      <Row
                        className={index % 2 === 0 ? "EvenRow" : "OddRow"}
                        style={{
                          alignItems: "start",
                          textAlign: "left",
                        }}
                      >
                        {Item.map((dataItem: any, index: any) => {
                          return (
                            <React.Fragment key={index}>
                              <Col
                                className="IndexDatatableNeoCol"
                                key={index}
                                style={{
                                  flex: columnWidthArray[index],
                                }}
                              >
                                {dataItem}
                              </Col>
                            </React.Fragment>
                          );
                        })}
                      </Row>
                    </div>
                  );
                })
              ) : (
                <div className="noListingPage">
                      <div>{ props?. message ? props?. message :"No Records Found"}
                        </div>
                </div>
              )}
            </div>
          </div>
          <div className="paginationDiv">
            <span>
              Showing {props.fromVal} to {props.toVal} of {props.totCnt} entries
            </span>
            <Pagination
              size="small"
              shape="rounded"
              count={props.totPages}
              color="primary"
              page={(props.offset * 10) / 10 + 1}
              onChange={props.handleChange}
              defaultPage={1}
              siblingCount={1}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Index;
