import React,{useState} from 'react'
import { Card, Row, Col } from 'reactstrap';
import { Grid } from '@mui/material';
import { isArray } from 'lodash';

function CatalogueProductInfo(props:any) {
  let {form}:any=props;
    const [toggleProductDetails, setToggleProductDetails]: any = useState({
        productSpecs: true,
        description: false,
        moreInfo: false
      })
    return (
        <div className='productinfoDiv'>
        <Card className='product-image-card'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={8}>
              <Row>
                {isArray(form?.profileURL) &&
                  form?.profileURL.map((ele: any, i: any) => {
                    if (ele?.show) {
                      return (
                        <>
                            <Col xs={12} sm={4}>
                            <img className="product-img" src={ele?.blobImage} alt="product image" width={'100%'} />
                            </Col >
                        </>)

                    }
                  })
                }
              </Row>

            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <h5>{form?.productName}</h5>
              <p>HKP Tag : {form?.SrNo}</p>
              <h5>₹{form?.sellPrice} MRP</h5>
            </Grid>
          </Grid>
        </Card>
        <Card className='product-info-card'>
          <h3 className='p-4'>Product Details</h3>
          <Grid
            container spacing={1}>
            <Grid item xs={12} md={2} >
              <div className="product-c">
                <p className='cursor-pointer'
                  onClick={() => setToggleProductDetails({
                    productSpecs: true
                  })}
                >PRODUCT SPECS</p>
                {
                  toggleProductDetails?.productSpecs &&
                  <hr className='p-hr'></hr>
                }
                <p className='cursor-pointer' onClick={() => setToggleProductDetails({
                  description: true
                })}>DESCRIPTION</p>
                {
                  toggleProductDetails?.description &&
                  <hr className='p-hr'></hr>
                }
                <p className='cursor-pointer' onClick={() => setToggleProductDetails({
                  moreInfo: true
                })}>MORE INFO</p>
                {
                  toggleProductDetails?.moreInfo &&
                  <hr className='p-hr'></hr>
                }
              </div>
              <div className='product-c-mobile'>
                <div>
                  <p className={toggleProductDetails?.productSpecs ? "p-moreInfo cursor-pointer" : 'cursor-pointer'}
                    onClick={() => setToggleProductDetails({
                      productSpecs: true
                    })}
                  >PRODUCT SPECS</p>

                </div>
                <div>
                  <p className={toggleProductDetails?.description ? "p-moreInfo cursor-pointer" : 'cursor-pointer'} onClick={() => setToggleProductDetails({
                    description: true
                  })}>DESCRIPTION</p>

                </div>
                <div>
                  <p className={toggleProductDetails?.moreInfo ? "p-moreInfo cursor-pointer" : 'cursor-pointer'} onClick={() => setToggleProductDetails({
                    moreInfo: true
                  })}>MORE INFO</p>
                  {/* {
              toggleProductDetails?.moreInfo &&
              <hr className='p-hr'></hr>
            } */}
                </div>

              </div>

            </Grid>
            <Grid item xs={12} md={10}>
              {
                toggleProductDetails?.productSpecs &&
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className='product-grid'>
                      <Row className='first-row'>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Artform</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.artform}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Type</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.type}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Category</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.category}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Sub-Category</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.subCategory}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Weave Type</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.weaveType}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Color of product</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.color}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Types of sarees by design</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.typeOfSareeByDesign}
                        </Col>
                      </Row>

                    </Grid>
                    <Grid item xs={12} md={6} className='product-grid'>
                      <Row className='first-row'>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Weaving Center</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.weavingCenter}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Name of Brand</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.nameOfBrand}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Zari Used</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.zariUsed?.label}
                        </Col>
                      </Row> <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Weight of Saree(in Grams)</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.weightOfSaree}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Weight of Zari used</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.weightOfZariUsed}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Purity of Zari</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.purity}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={5}>
                          <p className='product-p'>Yarn type</p>
                        </Col>
                        <Col xs={6} md={7} className='product-colR'>
                          {form?.yarnType}
                        </Col>
                      </Row>
                    </Grid>
                  </Grid>
                </>
              }
              {
                toggleProductDetails?.description && <Card className="description-card">

                </Card>
              }
              {
                toggleProductDetails?.moreInfo && <Card className="moreInfo-card">
                  <Row>
                    <Col xs={12} md={7} className='mt-4'>
                      <Row>
                        <Col xs={6}>
                          Country of Origin
                        </Col>
                        <Col xs={6}>
                          {form?.madeInIndia ? "India" : "N/A"}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          Handloom Mark
                        </Col>
                        <Col xs={6}>
                          {form?.handloom ? "Yes" : "No"}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          Silk Mark
                        </Col>
                        <Col xs={6}>
                          {form?.silkMark ? "Yes" : "No"}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          Loom Number
                        </Col>
                        <Col xs={6}>
                          {form?.loomNum}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          Design Number
                        </Col>
                        <Col xs={6}>
                          {form?.designNum}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={5}>

                    </Col>
                  </Row>

                </Card>
              }
            </Grid>



          </Grid>

        </Card>
      </div> 
    )
}

export default CatalogueProductInfo
